<template>
    <div class="companies-list">
        <teleport v-if="mountedComponent && isGeneralPlan" to="#companies-header">
            <TopNavigation :links="topNavigationLinks"/>
        </teleport>

        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem v-for="item in breadcrumbsLinks" :key="item.label" :active="item.active">
                <router-link v-if="item.to" :to="{ name: item.to }">{{ item.label }}</router-link>

                <span v-else>{{ item.label }}</span>
            </CBreadcrumbItem>
        </teleport>

        <router-view @onSelect="$emit('onSelect', $event)"/>
    </div>
</template>

<script>
import TopNavigation from '@/components/Page/TopNavigation'
import {mapGetters} from "vuex";

export default {
    name: 'Index',
    components: {TopNavigation},
    data() {
        return {
            mountedComponent: false,
            hideInvite: false,
            canInvite: false,
            topNavigationLinks: [
                {
                    title: 'Companies',
                    route: {
                        name: 'CompaniesList',
                    },
                    children: ['CompaniesList', 'CompaniesExisted'],
                },
                {
                    title: 'Invites',
                    route: {
                        name: 'CompaniesInvited',
                    },
                },
            ]
        }
    },
    computed: {
        ...mapGetters([
            'isGeneralPlan'
        ]),
        breadcrumbsLinks() {
            const invited = [
                {
                    to: 'CompaniesList',
                    label: 'Companies',
                },
                {
                    active: true,
                    label: 'Invites',
                },
            ]
            const exist = [
                {
                    active: true,
                    label: 'Companies',
                },
            ]

            return this.$route.name === 'CompaniesInvited' ? invited : exist
        },
    },
    mounted() {
        this.$nextTick(async () => {
            this.mountedComponent = true
        })
    },
}
</script>

<style scoped>
.companies-list {
}
</style>
